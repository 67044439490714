<template>
  <transition name="fade">
    <main role="main" v-if="itemLoading === false">
      <player
        :data="contentData"
        :episodes="+episodes"
        :advertise-data="advertiseData"
        @showModal="showModalAction"
      ></player>

      <section
        class="section section-video-description"
        v-if="itemLoading === false"
      >
        <div class="container m-container-only-s">
          <div class="row">
            <div class="col-10 col-ml-12 col-s-24">
              <h1 class="">
                {{ contentData.title }}
                <a
                  href=""
                  style="color: red"
                  @click.prevent="toggleFavorites(contentData)"
                >
                  <img
                    :class="{ 'opacity-40': !checkFavorites(contentData.id) }"
                    src="~@/assets/i/bookmark.svg"
                    alt=""
                  />
                </a>
              </h1>
              <p class="short-info">
                {{ contentData.country }} • {{ contentData.year }} •
                <template v-if="contentData.contentItems.length">
                  {{ contentData.contentItems[episodes || 0].durationSec / 60 }}
                  {{ $t("video-info.min") }} •
                </template>
                {{ contentData.age }}+
              </p>

              <ul>
                <li v-if="contentData.actors">
                  <span> {{ $t("video-info.actors") }}</span>
                  {{ contentData.actors }}
                </li>
                <li
                  v-if="
                    contentData.contentItems.length &&
                      contentData.contentItems[episodes || 0].audioTracks.length
                  "
                >
                  <span> {{ $t("video-info.audioTracks") }}</span>
                  <div
                    v-for="(item, index) in contentData.contentItems[
                      episodes || 0
                    ].audioTracks"
                    :key="index"
                  >
                    {{ item.name
                    }}{{
                      contentData.contentItems[episodes || 0].audioTracks[
                        index + 1
                      ]
                        ? ","
                        : ""
                    }}
                  </div>
                </li>
                <li v-if="contentData.directors">
                  <span> {{ $t("video-info.directors") }}</span>
                  {{ contentData.directors }}
                </li>
                <li
                  v-if="
                    contentData.contentItems.length &&
                      contentData.contentItems[episodes || 0].subTitles.length
                  "
                >
                  <span> {{ $t("video-info.subTitles") }}</span>
                  <div
                    v-for="(item, index) in contentData.contentItems[
                      episodes || 0
                    ].subTitles"
                    :key="index"
                  >
                    {{ item.name
                    }}{{
                      contentData.contentItems[episodes].subTitles[index + 1]
                        ? ","
                        : ""
                    }}
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="col-10 offset-2 col-sl-11 offset-sl-1 col-s-24 offset-s-0 col-text"
            >
              <p>{{ contentData.description }}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="section section-tabnav">
        <nav class="tabnav">
          <ul class="tabnav-items" v-if="contentData.contentItems.length">
            <li class="tabnav-offset-item"><div></div></li>
            <li
              class="tabnav-item tabnav-first-item"
              :class="{
                active: item.id === contentData.id,
                'tabnav-first-item': index === 0,
                'tabnav-last-item':
                  index !== 0 && contentData.seasons.length === index + 1
              }"
              :key="index"
              v-for="(item, index) in contentData.seasons"
            >
              <em></em>
              <router-link
                :to="{
                  name: 'Serials',
                  params: { id: item.id, episodes: 0 }
                }"
                class="tabnav-link"
                >{{ item.seasonTitle || item.title }}</router-link
              >
            </li>
            <li class="tabnav-offset-item"><div></div></li>
          </ul>
        </nav>
      </section>

      <section class="section section-video-list">
        <div class="container m-container-only-s">
          <ul>
            <li
              :key="index"
              :class="{ watched: index === +episodes }"
              v-for="(item, index) in contentData.contentItems"
            >
              <router-link
                :to="{
                  name: 'Serials',
                  params: { id: contentData.id, episodes: index }
                }"
              >
                <figure
                  v-bind:style="{
                    'background-image':
                      'url(' + replaceCoverImage(item.cover) + ')'
                  }"
                >
                  <div class="meter-container" v-if="index !== +episodes">
                    <div class="meter">
                      <span style="width: 0%"></span>
                    </div>
                  </div>
                  <i v-else></i>
                </figure>
                <strong
                  >{{ $t("serials.episode") }} {{ item.position }}:</strong
                >
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <payment-content
        :show="showModalBuyContent"
        :item="contentData"
        @closeModal="closeModal"
      ></payment-content>
      <success-payment-content
        :item="contentData"
        v-if="$route.query.payment === 'success' && !!$route.query.guid"
      ></success-payment-content>
      <error-payment-content
        :item="contentData"
        v-if="$route.query.payment === 'error' && !!$route.query.guid"
      ></error-payment-content>
    </main>
  </transition>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import cover from "@/mixins/cover";
import Player from "@/components/Player";
import events from "@/mixins/events";
import PaymentContent from "@/components/popup/PaymentContent";
import SuccessPaymentContent from "@/components/popup/SuccessPaymentContent";
import ErrorPaymentContent from "@/components/popup/ErrorPaymentContent";

export default {
  name: "SerialInfo",
  components: {
    ErrorPaymentContent,
    SuccessPaymentContent,
    PaymentContent,
    Player
  },
  props: ["id", "episodes"],
  mixins: [cover, events],
  computed: {
    ...mapState("content", {
      contentData: state => state.content,
      advertiseData: state => state.advertise,
      itemLoading: state => state.itemLoading
    }),
    ...mapState("order", {
      showModalBuyContent: state => state.showModalBuyContent
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapGetters("favorites", {
      checkFavorites: "CHECK_EXIST"
    }),
    episodeComp() {
      return this.episodes;
    }
  },

  beforeRouteLeave(to, from, next) {
    this.setEntityContent(["itemLoading", "empty"]);
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    await this.actionItem({ id: to.params.id }).then(() => {
      console.log("update");
      this._contentEvent(
        to.params.id,
        this.contentData.cdbId,
        this.contentData.contentItems[this.episodes || 0].position
      );
    });
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.actionItem({ id: to.params.id }).then(() => {
        vm._contentEvent(
          to.params.id,
          vm.contentData.cdbId,
          vm.contentData.contentItems[vm.episodes || 0].position
        );
      });
    });
  },
  watch: {
    itemLoading: {
      handler() {
        if (this.itemLoading === "empty") {
          this.actionItem({ id: this.$route.params.id });
        }
      },
      immediate: false
    }
  },
  methods: {
    ...mapActions("content", {
      actionItem: "item"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    ...mapMutations("content", {
      setEntityContent: "SET_ENTITY"
    }),
    ...mapActions("favorites", {
      toggleFavorites: "toggleEntity"
    }),
    ...mapMutations("order", {
      setEntityOrder: "SET_ENTITY"
    }),
    ...mapActions("order", {
      modalBuyContent: "showModalBuyContent"
    }),
    showModalAction() {
      this.modalBuyContent();
      document.documentElement.classList.add("modal-opened");
    },
    closeModal() {
      document.documentElement.classList.remove("modal-opened");
      this.setEntityOrder(["showModalBuyContent", false]);
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
