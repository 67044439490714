var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[(_vm.itemLoading === false)?_c('main',{attrs:{"role":"main"}},[_c('player',{attrs:{"data":_vm.contentData,"episodes":+_vm.episodes,"advertise-data":_vm.advertiseData},on:{"showModal":_vm.showModalAction}}),(_vm.itemLoading === false)?_c('section',{staticClass:"section section-video-description"},[_c('div',{staticClass:"container m-container-only-s"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 col-ml-12 col-s-24"},[_c('h1',{},[_vm._v(" "+_vm._s(_vm.contentData.title)+" "),_c('a',{staticStyle:{"color":"red"},attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleFavorites(_vm.contentData)}}},[_c('img',{class:{ 'opacity-40': !_vm.checkFavorites(_vm.contentData.id) },attrs:{"src":require("@/assets/i/bookmark.svg"),"alt":""}})])]),_c('p',{staticClass:"short-info"},[_vm._v(" "+_vm._s(_vm.contentData.country)+" • "+_vm._s(_vm.contentData.year)+" • "),(_vm.contentData.contentItems.length)?[_vm._v(" "+_vm._s(_vm.contentData.contentItems[_vm.episodes || 0].durationSec / 60)+" "+_vm._s(_vm.$t("video-info.min"))+" • ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.contentData.age)+"+ ")],2),_c('ul',[(_vm.contentData.actors)?_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("video-info.actors")))]),_vm._v(" "+_vm._s(_vm.contentData.actors)+" ")]):_vm._e(),(
                  _vm.contentData.contentItems.length &&
                    _vm.contentData.contentItems[_vm.episodes || 0].audioTracks.length
                )?_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("video-info.audioTracks")))]),_vm._l((_vm.contentData.contentItems[
                    _vm.episodes || 0
                  ].audioTracks),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.name)+_vm._s(_vm.contentData.contentItems[_vm.episodes || 0].audioTracks[ index + 1 ] ? "," : "")+" ")])})],2):_vm._e(),(_vm.contentData.directors)?_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("video-info.directors")))]),_vm._v(" "+_vm._s(_vm.contentData.directors)+" ")]):_vm._e(),(
                  _vm.contentData.contentItems.length &&
                    _vm.contentData.contentItems[_vm.episodes || 0].subTitles.length
                )?_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("video-info.subTitles")))]),_vm._l((_vm.contentData.contentItems[
                    _vm.episodes || 0
                  ].subTitles),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.name)+_vm._s(_vm.contentData.contentItems[_vm.episodes].subTitles[index + 1] ? "," : "")+" ")])})],2):_vm._e()])]),_c('div',{staticClass:"col-10 offset-2 col-sl-11 offset-sl-1 col-s-24 offset-s-0 col-text"},[_c('p',[_vm._v(_vm._s(_vm.contentData.description))])])])])]):_vm._e(),_c('section',{staticClass:"section section-tabnav"},[_c('nav',{staticClass:"tabnav"},[(_vm.contentData.contentItems.length)?_c('ul',{staticClass:"tabnav-items"},[_c('li',{staticClass:"tabnav-offset-item"},[_c('div')]),_vm._l((_vm.contentData.seasons),function(item,index){return _c('li',{key:index,staticClass:"tabnav-item tabnav-first-item",class:{
              active: item.id === _vm.contentData.id,
              'tabnav-first-item': index === 0,
              'tabnav-last-item':
                index !== 0 && _vm.contentData.seasons.length === index + 1
            }},[_c('em'),_c('router-link',{staticClass:"tabnav-link",attrs:{"to":{
                name: 'Serials',
                params: { id: item.id, episodes: 0 }
              }}},[_vm._v(_vm._s(item.seasonTitle || item.title))])],1)}),_c('li',{staticClass:"tabnav-offset-item"},[_c('div')])],2):_vm._e()])]),_c('section',{staticClass:"section section-video-list"},[_c('div',{staticClass:"container m-container-only-s"},[_c('ul',_vm._l((_vm.contentData.contentItems),function(item,index){return _c('li',{key:index,class:{ watched: index === +_vm.episodes }},[_c('router-link',{attrs:{"to":{
                name: 'Serials',
                params: { id: _vm.contentData.id, episodes: index }
              }}},[_c('figure',{style:({
                  'background-image':
                    'url(' + _vm.replaceCoverImage(item.cover) + ')'
                })},[(index !== +_vm.episodes)?_c('div',{staticClass:"meter-container"},[_c('div',{staticClass:"meter"},[_c('span',{staticStyle:{"width":"0%"}})])]):_c('i')]),_c('strong',[_vm._v(_vm._s(_vm.$t("serials.episode"))+" "+_vm._s(item.position)+":")]),_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)])]),_c('payment-content',{attrs:{"show":_vm.showModalBuyContent,"item":_vm.contentData},on:{"closeModal":_vm.closeModal}}),(_vm.$route.query.payment === 'success' && !!_vm.$route.query.guid)?_c('success-payment-content',{attrs:{"item":_vm.contentData}}):_vm._e(),(_vm.$route.query.payment === 'error' && !!_vm.$route.query.guid)?_c('error-payment-content',{attrs:{"item":_vm.contentData}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }